<template>
    <modal ref="modalLiquidarComision" :titulo="`Liquidar comisiones del ${$config.vendedor}`" tamano="modal-lg" no-aceptar adicional="Liquidar" @adicional="liquidarComisiones">
        <div class="row mx-0 align-items-center">
            <img :src="lechero.logo_lechero" width="55" height="55" class="rounded-circle border" />
            <div class="col-3">
                <p class="text-general f-17 f-500">
                    {{ lechero.lechero }}
                </p>
                <p class="text-general f-13">
                    {{ lechero.cedis_nombre }}
                </p>
            </div>
            <div class="col text-center text-general">
                <p class="f-20"> <b>{{ separadorNumero(totalComision) }}</b> {{ sigla }}</p>
                <p>
                    <i class="icon-comision f-14 text-general" />
                    Valor a liquidar
                </p>
            </div>
            <div class="col px-0 text-center text-general">
                <p class="text-general f-12">Observación del pago de la liquidación</p>
                <el-input v-model="comentario" placeholder="Observación" class="w-100" type="textarea" :rows="4" />
            </div>
        </div>
        <div class="row mx-0 text-general f-500 mb-3 mt-2">
            Deuda del {{ $config.vendedor }}
        </div>
        <div class="row">
            <div class="col border-right text-center text-general">
                <p class="f-16 f-500">{{ convertMoney(deudaGeneral.total) }}</p>
                <p class="f-13">Crédito Total</p>
            </div>
            <div class="col border-right text-center text-general">
                <p class="f-16 f-500">{{ convertMoney(deudaGeneral.vigente) }}</p>
                <p class="f-13">Deuda no vencida</p>
            </div>
            <div class="col border-right text-center text-general">
                <p class="f-16 f-500">{{ convertMoney(deudaGeneral.menos30) }}</p>
                <p class="f-13">Deuda a 30 días</p>
            </div>
            <div class="col border-right text-center text-general">
                <p class="f-16 f-500">{{ convertMoney(deudaGeneral.menos60) }}</p>
                <p class="f-13">Deuda a 60 días</p>
            </div>
            <div class="col border-right text-center text-general">
                <p class="f-16 f-500">{{ convertMoney(deudaGeneral.menos90) }}</p>
                <p class="f-13">Deuda a 90 días</p>
            </div>
            <div class="col text-center text-general">
                <p class="f-16 f-500">{{ convertMoney(deudaGeneral.mas90) }}</p>
                <p class="f-13">Deuda a +90 días</p>
            </div>
        </div>
        <div class="row mx-0 pt-4 ">
            <div class="col-12 text-general f-16 f-500 my-2">
                {{ pedidos.length }} Pedidos comisionados
            </div>
            <div class="col-12 px-0">
                <div class="custom-scroll overflow-auto" :style="`height:${alto_tabla}`">
                    <el-table ref="tablaPedidos" :data="pedidos" header-row-class-name="text-general" style="width: 100%" @selection-change="seleccion">
                        <el-table-column type="selection" width="55" class-name="check-general" />
                        <el-table-column prop="id" label="Pedido" class-name="text-center text-general" />
                        <el-table-column label="Fecha Pedido" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ row.entrega_fecha | helper-fecha('DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor Pedido" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ convertMoney(row.total,row.idm_moneda) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Comisión" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ convertMoney(row.valor_condicion,row.idm_moneda) }}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Liquidacion from '~/services/comisiones/liquidar'
export default {
    data(){
        return {

            comentario: '',
            buscar: '',
            alto_tabla: 'calc(100vh - 592px)',
            deudaGeneral:{},
            pedidos:[],
            //totalComision:0,
            lechero:{},
            pedidosSeleccionados:[]
        }
    },
    computed:{
        sigla(){
            let m = this.monedas.find(o=>o.id === this.lechero.idm_moneda)
            if(m){ return m.sigla }
            return ''
        },
        totalComision(){
            let valor = 0
            for (const p of this.pedidosSeleccionados){
                valor += parseFloat(p.valor_condicion)
            }
            return valor
        }
    },
    mounted(){
        //console.log(window.innerHeight);
        if(window.innerHeight <= 680){
            this.alto_tabla = 'calc(100vh - 484px)'
        }
    },
    methods: {
        toggle(id_tienda){
            this.detalleComisiones(id_tienda)
            this.comentario = ''
            this.$refs.modalLiquidarComision.toggle();
        },
        async detalleComisiones(id_tienda){
            try {

                const {data} = await Liquidacion.detalleComisiones(id_tienda)
                this.deudaGeneral = {
                    vigente:data.deuda_vigente,
                    menos30:data.deuda_menos30,
                    menos60:data.deuda_menos60,
                    menos90:data.deuda_menos90,
                    mas90:data.deuda_mas90,
                    total:data.deuda_total,
                }
                this.pedidos = data.pedidos

                let that = this
                setTimeout(function(){
                    data.pedidos.forEach(row => {
                        that.$refs.tablaPedidos.toggleRowSelection(row);
                    });
                }, 1000);
                this.lechero = data.lechero

            } catch (e){
                this.error_catch(e)
            }
        },
        async liquidarComisiones(){
            try {
                let pedidos = []
                for (const p of this.pedidosSeleccionados){
                    pedidos.push(p.id)
                }
                if(pedidos.length === 0) return
                let form = {
                    comentario: this.comentario,
                    ids_pedidos: pedidos
                }
                const {data} = await Liquidacion.liquidarComisiones(this.lechero.id, form)
                this.notificacion('','Comisiones liquidadas correctamente', 'success')
                this.$emit('update')
                this.$refs.modalLiquidarComision.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        seleccion(val,e){
            this.pedidosSeleccionados = val;
        }
    }
}
</script>
